import React from "react";
import { useParams } from "react-router-dom";
import ContentfulContainer from "../../../container/ContentfulContainer";
import ContentfulPage from "../../ContentfulPage/ContentfulPage";
import Footer from "../../Footer/Footer";

const LandingPage = ({ preview }) => {
    let { slug } = useParams();

    return (
        <>
            <ContentfulContainer preview={preview} slug={slug}>
                <ContentfulPage />
            </ContentfulContainer>
            <Footer />
        </>
    )
}

export default LandingPage;