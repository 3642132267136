import React, { Fragment } from "react";
import constants from "../../constants";
import GridColumn from "../gridSystem/GridColumn/GridColumn";
import BarList from "../BarList/BarList";
import Grid from "../gridSystem/Grid/Grid";
import Block from "../Block/Block";

const OverviewL1 = ({ data, loading }) => (
    <Block
        color="white"
        title="Success Factors"
    >
        <Grid>
            {(Object.values(constants.l0).map(level => (
                <Fragment key={level.name + 'titleBarList'}>
                    <GridColumn size={12} displayOnly="mobile">
                        <h2 className={`Overview__title Overview__title--${level.color} Overview__title--mobile`}>{level.name}</h2>
                    </GridColumn>
                    {(!loading && data) && Object.values(data[level.id].children).map((child, index) => (
                        <GridColumn key={child.id + 'barlist'} size={4} desktopOrder={index + 1} noPadding={true}>
                            <div className="Overview__barList">
                                <h3 className="Overview__title--small">{child.description}</h3>
                                <BarList
                                    data={{
                                        ...child,
                                        children: Object.values(child.children).reduce((prev, curr) => ({
                                            ...prev,
                                            [curr.id]: {
                                                ...curr,
                                                data: [
                                                    {
                                                        id: 'main',
                                                        progress: Math.round(curr.score),
                                                        gradient: true,
                                                        color: level.color,
                                                        dotColor: level.color,
                                                        hasDot: true,
                                                        tooltip: true,
                                                        prefix: ''
                                                    }
                                                ]
                                            }
                                        }), {})
                                    }}
                                    color={level.color} prefix={level.name[0] + (index + 1) + '.'} />
                            </div>
                        </GridColumn>
                    ))}
                </Fragment>
            )))}
        </Grid>
    </Block>
);

export default OverviewL1;