import React from "react";
import constants from "../../constants";
import GridColumn from "../gridSystem/GridColumn/GridColumn";
import Grid from "../gridSystem/Grid/Grid";
import Curve from "../Curve/Curve";
import './Hero.scss';

const Hero = ({ score, onClick, error, name, tag }) => (
    <div className="Hero">
        <Grid gridRowGap="2rem">
            <GridColumn size={6} desktopOrder={0}>
                <div className="Hero__content">
                    <div className="Hero__contentWrapper">
                        <h1 className={`Hero__title${!name ? '--hidden' : ''}`}>{name || 'Company'}</h1>
                        {(constants.tags[tag] && constants.tags[tag].text) || constants.tags.default.text}
                    </div>
                </div>
            </GridColumn>
            <GridColumn size={6} desktopOrder={1} height="100%">
                <div className="Hero__progress">
                    <Curve score={Math.round(score)} />
                </div>
            </GridColumn>
            <GridColumn size={12} desktopOrder={3}>
                <div className="Hero__line"></div>
            </GridColumn>
        </Grid>
    </div >
)

export default Hero;