import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../Button/Button";
import { loginAuth0Params } from "../../helpers";
import './LoginButton.scss';

const LoginButton = ({ company, typeformId, company_ref, tag }) => {
    const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

    return !isAuthenticated ?
        <Button color="black" onClick={() => loginWithRedirect(loginAuth0Params(company, company_ref, typeformId, tag, null, null, null))}>Start</Button> :
        <p className="LoginButton" onClick={() => logout({ returnTo: window.location.origin })}>Log out</p>
}

export default LoginButton;