import React from "react";
import './GridColumn.scss';

const GridColumn = ({ children, size, mobileSize, noPadding, desktopOrder, displayOnly, align, height, gridRow }) => (
    <div
        className={`GridColumn GridColumn--${size} GridColumn--${align}  ${mobileSize ? `GridColumn--mobile${mobileSize}` : ''} ${noPadding ? 'GridColumn--nopadding' : ''} ${desktopOrder ? `GridColumn--desktopOrder${desktopOrder}` : ''} ${displayOnly ? `GridColumn--${displayOnly}` : ''}`}
        style={{ height, gridRow }}
    >
        {children}
    </div>
)

export default GridColumn;