import React, { useState, useEffect } from "react";
import { getContentById } from "../../api/contentful";
import BlocksContainer from "../../container/BlocksContainer";
import SplineBlock from "../../components/SplineBlock/SplineBlock";

import './LayoutRow.scss';

const LayoutRow = (props) => {
    const { sections, splineUrl } = props;
    const [entries, setEntries] = useState([]);
    useEffect(() => {
        const getEntries = async sections => {
            for (let i = 0; i < sections.length; i++) {
                const section = sections[i];
                let entry;
                if (section.sys.contentType) {
                    entry = section;
                } else {
                    entry = await getContentById(section.sys.id);
                }
                setEntries(entries => [...entries, entry]);
            }
        }
        getEntries(sections)
    }, [sections]);

    return (
        <div className="LayoutRow" style = {{ gridTemplateColumns: `repeat(${entries.length}, 1fr)` }}>
            {splineUrl && (
                <SplineBlock splineUrl={splineUrl} type="background" />
            )}
    
{
    entries.map(entry => (
        <div key={entry.sys.id} className="LayoutRow__column">
            <BlocksContainer type={entry.sys.contentType.sys.id} content={entry} />
        </div>
    ))
}
        </div >
    )
}

export default LayoutRow;