import { cloneElement, useEffect, useState } from "react";
import { getContent, getPreviewContent } from "../api/contentful";

const ContentfulContainer = ({ preview, slug, children }) => {
    const [content, setContent] = useState(null);

    useEffect(() => {
        if (!!preview) {
            getPreviewContent(slug)
                .then(content => setContent(content));
        } else {
            getContent(slug)
                .then(content => setContent(content));
        }

    }, [preview, slug]);

    return cloneElement(children, { content });
}

export default ContentfulContainer;