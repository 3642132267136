import React from "react";
import './BarListYlines.scss';

const BarListYlines = ({ lines, hideScale, height }) => {
    const line0 = <span className="BarListYlines--line--0">0</span>;
    return (
        <div className="BarListYlines" style={{ height }}>
            {lines.map((line, index) => (
                <div key={line} className={`BarListYlines--line ${line === 100 ? 'BarListYlines--100' : ''} ${hideScale ? 'BarListYlines--hide' : ''}`}>{index === 0 ? line0 : ''}<span className={`${line === 0 ? 'BarListYlines--line--0' : ''}`}>{line}</span></div>
            ))}
        </div>
    )
};

export default BarListYlines;