import React, { useState } from "react";
import { useEffect } from "react";
import { sendContactInfo } from "../../api/typeform";
import Button from '../Button/Button';
import ContactForm from "./ContactForm";
import './Contact.scss';

const Contact = ({ user }) => {
    const [openForm, setOpenForm] = useState(false);
    const [form, setForm] = useState({
        email: '',
        phone: '',
        message: '',
        name: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [errorSubmit, setErrorSubmit] = useState(false);

    useEffect(() => {
        setForm(form => ({ ...form, email: user.email, company: user.company, name: user.nickname.charAt(0).toUpperCase() + user.nickname.slice(1) }));
    }, [user]);

    const handleOnChange = e => {
        setForm(form => ({
            ...form,
            [e.target.name]: e.target.value
        }));
    }

    const handleOnSubmit = () => {
        if (form.email !== '' && form.name !== '') {
            sendContactInfo(form, user.__raw).then(res => {
                if (res.status !== 200) {
                    setErrorSubmit(true);
                } else {
                    setOpenForm(false);
                    setIsSubmitted(true);
                    setErrorSubmit(false);
                }
            });
        } else {
            setError(true)
        }
    }

    return (
        <>
            {!isSubmitted && (
                <>
                    <h3>Do you want to know more?</h3>
                    <p>We’re here to help you better understand your results and how TransitionView can be scaled up and used by your whole organisation for more detailed insights to inform your sustainability work.</p>
                    <ContactForm form={form} onChange={handleOnChange} error={error} />
                    <div className='Contact__buttonWrapper'>
                        <Button onClick={handleOnSubmit}><div className='Contact__button'>Send</div></Button>
                    </div>
                </>
            )}
            {errorSubmit && <p>Sorry, something went wrong!<br />Please try to reload page or report to dev@anothertomorrow.io</p>}
            {(!openForm && isSubmitted) && (
                <>
                    <h3>Thank you!</h3>
                    <p>We will be in touch shortly!</p>
                </>
            )
            }
        </>
    )
}

export default Contact;