import React from "react";
import Button from "../Button/Button";
import './CtaButton.scss';

const CtaButton = ({ label, url, showAsLink, textColor, backgroundColor }) => (
    <div className="CtaButton">
        <a href={url}>
            {!showAsLink ? (
                <Button color={backgroundColor} textColor={textColor}>
                    <div className="CtaButton__label">{label}</div>
                </Button>
            ): (
                <div className={`CtaButton__link--${textColor ? textColor : 'white'}`}>
                    {label}
                </div>
            )}
        </a>
    </div>
)

export default CtaButton;