import colors from "./styles/colors";

const constants = {
    MAX_SCORE: 100,
    REF_SCORE: 45,
    l0: {
        willingness: {
            id: 'willingness',
            name: 'Willingness',
            color: 'yellow',
            colorCode: colors.color__yellow,
            description: 'Lorem ipsum dolor sit amet. Est impedit velit est facilis consequatur ex quam vitae in galisum cupiditate id dolores omnis. Ex voluptas dolorem ut debitis molestias et error dolor sit galisum voluptas.'
        },
        readiness: {
            id: 'readiness',
            name: 'Readiness',
            color: 'blue',
            colorCode: colors.color__blue,
            description: 'Lorem ipsum dolor sit amet. Est impedit velit est facilis consequatur ex quam vitae in galisum cupiditate id dolores omnis. Ex voluptas dolorem ut debitis molestias et error dolor sit galisum voluptas.'
        },
        openness: {
            id: 'openness',
            name: 'Trustworthiness',
            color: 'pink',
            colorCode: colors.color__pink,
            description: 'Lorem ipsum dolor sit amet. Est impedit velit est facilis consequatur ex quam vitae in galisum cupiditate id dolores omnis. Ex voluptas dolorem ut debitis molestias et error dolor sit galisum voluptas.'
        }
    },
    tags: {
        ns102: {
            id: 'ns102',
        },
        default: {
            id: 'default',
            text: (
                <p>This is your individual Transition Capability Score, built up by the key blocks Willingness, Readiness, and Openness — all critical to drive an active and dynamic green transition. Learn more below.</p>
            )
        }
    }
}

export default constants;