import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './TextBlock.scss';

const TextBlock = ({ text, textColor, alignText, noPadding }) => (
    <div className={`TextBlock--${alignText ? alignText : 'left'} ${textColor ? `TextBlock--${textColor}` : ''} ${noPadding ? 'TextBlock--noPadding' : ''}`}>
        {documentToReactComponents(text)}
    </div>
);

export default TextBlock;