import React from "react";
import './MediaBlock.scss';

const MediaBlock = ({ fields }) => {
    switch (fields.file.contentType) {
        case 'image/png':
        case 'image/jpeg':
            return <img className="MediaBlock" alt={fields.title} src={fields.file.url} />
        case 'video/mp4':
            return (
                <video className="MediaBlock" autoPlay muted loop>
                    <source src={fields.file.url} type="video/mp4"></source>
                    Your browser does not support the video tag.
                </video>
            )
        default:
            return null;
    }
}

export default MediaBlock;