import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from '../../Loader/Loader';
import { submitAnswers } from "../../../api/typeform";
import Alert from "../../Alert/Alert";
import './SubmissionPage.scss';

const SubmissionPage = () => {
    const navigate = useNavigate();
    const { isAuthenticated, user, getIdTokenClaims, isLoading } = useAuth0();
    const [error, setError] = useState(null);

    const handleError = () => {
        let time = 10;
        let interval = setInterval(() => {
            time -= 1;
            setError("Oups, it seems like the confirmation I was expecting from our services is lagging. Don't worry, your response has been submitted, but unfortunately you will have to wait until later to view your individual result. You will be redirected to the startpage..." + time);
            if (time === 0) {
                navigate("/");
            }
        }, 1000);
        return () => clearInterval(interval);
    }

    useEffect(() => {
        if (user) {
            getIdTokenClaims().then(user => {
                if (user) {
                    let userTypeformId, typeformToken, companyId;
                    const company = user.company;
                    if (user['survey/member']) {
                        userTypeformId = user['survey/id'];
                        typeformToken = user['survey/token'] || user.typeformToken;
                    } else {
                        userTypeformId = user.typeformId;
                        typeformToken = user.typeformToken;
                        companyId = user.companyId;
                    }

                    const typeformId = userTypeformId || process.env.REACT_APP_TYPEFORM_ID;
                    const timeoutId = setTimeout(() => {
                        submitAnswers(user.__raw, typeformId, typeformToken, companyId, company).then(res => {
                            if (res.status !== 200) {
                                // Try one more time after 2 seconds
                                const timeoutId2 = setTimeout(() => {
                                    submitAnswers(user.__raw, typeformId, typeformToken, companyId, company).then(res2 => {
                                        if (res2.status !== 200) {
                                            handleError();
                                        } else {
                                            setError(null);
                                            navigate("/result");
                                        }
                                    });
                                }, 2000);
                                return () => clearTimeout(timeoutId2);
                            } else {
                                setError(null);
                                navigate("/result");
                            }
                        });
                    }, 3000);

                    return () => clearTimeout(timeoutId);
                } else {
                    handleError();
                }
            });
        }
    }, [user, getIdTokenClaims, navigate]);

    if (!isAuthenticated && !isLoading) {
        navigate("/");
    }

    return (
        <div className="SubmissionPage">
            {error ? <Alert text={error} /> : <Loader />}
        </div>
    );
}

export default SubmissionPage;