import React from "react";
import ContactBlock from "../components/ContactBlock/ContactBlock";
import CtaButton from "../components/CtaButton/CtaButton";
import ImageTitleBlock from "../components/ImageTitleBlock/ImageTitleBlock";
import LayoutBlock from "../components/LayoutBlock/LayoutBlock";
import LayoutRow from "../components/LayoutRow/LayoutRow";
import SplineBlock from "../components/SplineBlock/SplineBlock";
import TextAndImageBlock from "../components/TextAndImageBlock/TextAndImageBlock";
import TextBlock from "../components/TextBlock/TextBlock";
import VideoBlock from "../components/VideoBlock/VideoBlock";

const BlocksContainer = ({ type, content, noPadding }) => {
    switch (type) {
        case 'blockText':
            return <TextBlock {...content.fields} noPadding={noPadding} />
        case 'textAndImageBlock':
            return <TextAndImageBlock {...content.fields} noPadding={noPadding} />;
        case 'layoutRow':
            return <LayoutRow {...content.fields} noPadding={noPadding} />
        case 'layoutBlock':
            return <LayoutBlock {...content.fields} noPadding={noPadding} />;
        case 'ctaButton':
            return <CtaButton {...content.fields} noPadding={noPadding} />
        case 'blockSpline':
            return <SplineBlock {...content.fields} noPadding={noPadding} />
        case 'blockVideo':
            return <VideoBlock {...content.fields} noPadding={noPadding} />
        case 'blockContactForm':
            return <ContactBlock {...content.fields} noPadding={noPadding} />
        case 'blockImageAndTitle':
            return <ImageTitleBlock {...content.fields} noPadding={noPadding} />
        default:
            return null;
    }
}

export default BlocksContainer;