import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import ResultPage from "./components/pages/Result/ResultPage";
import LandingPage from "./components/pages/LandingPage/LandingPage";
import SubmissionPage from "./components/pages/SubmissionPage/SubmissionPage";
import './App.scss';
import TypeformPage from "./components/pages/TypeformPage/TypeformPage";

const Redirect = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const company = searchParams.get("company");
  const company_ref = searchParams.get("company_ref");
  const typeform = searchParams.get("form");
  const tag = searchParams.get("tag");
  const invitation = searchParams.get("invitation");
  const organization = searchParams.get("organization");
  const organization_name = searchParams.get("organization_name");

  useEffect(() => {
    let stringArray = [];
    let s = '';
    if (company) stringArray = [...stringArray, `company=${company}`];
    if (company_ref) stringArray = [...stringArray, `company_ref=${company_ref}`];
    if (typeform) stringArray = [...stringArray, `form=${typeform}`];
    if (tag) stringArray = [...stringArray, `tag=${tag}`];
    if (invitation) stringArray = [...stringArray, `invitation=${invitation}`];
    if (organization) stringArray = [...stringArray, `organization=${organization}`];
    if (organization_name) stringArray = [...stringArray, `organization_name=${organization_name}`];
    if (stringArray.length > 0) s = '?' + stringArray.join('&');
    navigate("/result" + s);
  }, [navigate, company, typeform, tag, invitation, organization, organization_name, company_ref])
  return null;
}

const App = () => (
  <Routes>
    <Route path="/" element={<Redirect />} />
    <Route path="/submission" element={<SubmissionPage />} />
    <Route path="/typeform" element={<TypeformPage />} />
    <Route path="/result" element={<ResultPage />} />
    <Route path="/result/preview" element={<ResultPage preview={true} />} />
    <Route path="/:slug" element={<LandingPage />} />
    <Route path="/:slug/preview" element={<LandingPage preview={true} />} />
  </Routes>
)

export default App;
