const contentful = require("contentful");

const client = contentful.createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
});

const clientPreview = contentful.createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE,
    accessToken: process.env.REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN,
    host: "preview.contentful.com"
})

export const getContent = async slug => client.getEntries({ content_type: 'page', 'fields.path': slug }).then(entries => {
    if (entries.items.length > 0) return entries.items[0].fields;
    return;
});

export const getPreviewContent = async slug => clientPreview.getEntries({ content_type: 'page', 'fields.path': slug }).then(entries => {
    if (entries.items.length > 0) return entries.items[0].fields;
    return;
});

export const getContentById = async (id) => client.getEntry(id).then(entry => entry);