import React, { Fragment } from "react";
import BarChartYlines from "./BarChartYlines";
import './BarChart.scss';

const BarChart = ({ data, compressed }) => (
    <div className="BarChart">
        <div className="BarChart__chart">
            <BarChartYlines />
            <div className="BarChart__container">
                {!!data && data.map(d => (
                    <div key={'barchart' + d.id} className={`BarChart__barContainer${!!compressed ? '--compressed' : ''}`}>
                        {d.data.map((datapoint, index) => (
                            <Fragment key={datapoint.label}>
                                <div className="BarChart__barWrapper">
                                    <div className={`BarChart__bar${!!compressed ? '--compressed' : ''}`} style={{ height: `${datapoint.score}%`, backgroundColor: datapoint.colorCode || d.colorCode }}>
                                        {!compressed && <span className="BarChart__data">{Math.round(datapoint.score)}</span>}
                                        <span className="BarChart__label">{datapoint.label}</span>
                                    </div>
                                </div>
                                {index === 0 && <span className="BarChart__label--mobile">{d.id}</span>}
                            </Fragment>
                        ))}
                    </div>
                ))}
            </div>
        </div>
        <div className="BarChart__legends">
            {!!data && data.map(d => (
                <div key={'legend' + d.id}>
                    {d.data.map(datapoint => (
                        <p key={datapoint.id} className={`BarChart__legend--${d.color}`}>{datapoint.label} <span className="BarChart__legend--bold" style={{ color: d.colorCode }}>({Math.round(datapoint.score)})</span></p>
                    ))}
                </div>
            ))}
        </div>
    </div>
);

export default BarChart;