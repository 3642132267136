let colors = {
    // GRAY SCALE
    color__black: "#0f0f0f",
    color__white: "#fff",
    "color__gray-0": "#222222",
    "color__gray-1": "#333333",
    "color__gray-2": "#989898",
    "color__gray-3": "#d0d0d0",
    "color__gray-4": "#ededed",
    "color__gray-5": "#f5f5f5",
    // COLORS
    color__green: "#31b468",
    color__yellow: "#ffd166",
    color__blue: "#118ab2",
    color__pink: "#ef476f",
    color__turquoise: "#50c9ce",
    color__purple: "#9883e5",
    "color__blue-light": "#72a1e5",
    "color__green-light": "#19c587",
    color__red: "#f75555",
    color__orange: "#ff8b66"
}

colors.color__primary = colors.color__green;
colors.color__ok = colors['color__green-light'];
colors.color__warning = colors.color__orange;
colors.color__danger = colors.color__red;

export default colors;