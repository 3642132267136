import { useAuth0 } from "@auth0/auth0-react";
import { Widget } from "@typeform/embed-react";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const TypeformPage = () => {
    const [user, setUser] = useState(null);
    const { isAuthenticated, user: authUser, isLoading } = useAuth0();

    useEffect(() => {
        if (authUser) {
            const company = authUser.companyId || authUser.company;
            const typeformId = (authUser['survey/member'] && authUser['survey/id']) || authUser.typeformId || process.env.REACT_APP_TYPEFORM_ID;
            setUser({
                ...authUser,
                typeformId,
                company
            })
        }
    }, [authUser]);

    if (isLoading) {
        return;
    }

    if (!isAuthenticated && !isLoading) {
        return Navigate({ to: '/' })
    }

    return (user && user.typeformId) && <Widget
        id={user.typeformId}
        style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}
        hidden={{ company: authUser.company, usertoken: (authUser['survey/member'] && authUser['survey/token']) || authUser.typeformToken }}
    />
}

export default TypeformPage;