import React, { useEffect, useState, useRef } from "react";
import './LayoutSection.scss';

const LayoutSection = ({ children, backgroundColor, backgroundImage }) => {
    const [isVisible, setIsVisible] = useState(true);
    const domRef = useRef();

    useEffect(() => {
        const ref = domRef.current;
        const observer = new IntersectionObserver(enteries => {
            for (let i = 0; i < enteries.length; i++) {
                setIsVisible(enteries[i].isIntersecting)
            }
        });
        observer.observe(ref);
        return () => observer.unobserve(ref);
    }, []);

    return (
        <div
            className={`LayoutSection LayoutSection${backgroundColor ? `--${backgroundColor}` : ''}`}
            style={{ ...(backgroundImage && backgroundImage.fields.file.contentType === 'image/png' ? { backgroundImage: `url(${backgroundImage.fields.file.url})` } : {}) }}
        >
            {(backgroundImage && backgroundImage.fields.file.contentType === 'video/mp4') && (
                <video className="LayoutSection__video" src={backgroundImage.fields.file.url} muted autoPlay loop />
            )}
            <div className={`LayoutSection__inner${isVisible ? '--isVisible' : ''}`} ref={domRef}>
                {children}
            </div>
        </div >
    )
}

export default LayoutSection;