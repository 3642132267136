import React from "react";
import './Loader.scss';

const Loader = () => (
    <div className="Loader">
        <span className="Loader__bar--1"></span>
        <span className="Loader__bar--2"></span>
        <span className="Loader__bar--3"></span>
    </div>
)

export default Loader;