import React from "react";
import './InputText.scss';

const InputText = ({ name, label, value, handleOnChange }) => {
    return (
        <div className="InputText">
            <input className="InputText__input" type="text" name={name} value={value} onChange={handleOnChange} />
            <label className="InputText__label" htmlFor={name}>{label}</label>
        </div>
    )
}

export default InputText;