import React from 'react';
import './Popup.scss';

const Popup = ({ onClick, children }) => (
    <div className='Popup'>
        <div className='Popup__cross' onClick={onClick}>X</div>
        {children}
    </div>
)

export default Popup;