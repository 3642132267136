import React from "react";
import './BarChartYlines.scss';

const BarChartYlines = () => (
    <div className="BarChartYlines">
        <div className="BarChartYlines--line BarChartYlines--100"></div>
        <div className="BarChartYlines--line BarChartYlines--80"></div>
        <div className="BarChartYlines--line BarChartYlines--60"></div>
        <div className="BarChartYlines--line BarChartYlines--40"></div>
        <div className="BarChartYlines--line BarChartYlines--20"></div>
        <div className="BarChartYlines--line BarChartYlines--0"></div>
    </div>
)

export default BarChartYlines;