import React from "react";
import { useState } from "react";
import { sendContactInfo } from "../../api/typeform";
import Button from "../Button/Button";
import InputText from "../InputText/InputText";
import TextArea from "../TextArea/TextArea";
import TextBlock from "../TextBlock/TextBlock";
import './ContactBlock.scss';

const ContactBlock = ({ title, text, textColor, tag, thankYouMessage }) => {
    const [form, setForm] = useState({
        name: '',
        email: '',
        message: '',
        tag
    });
    const [error, setError] = useState(false);
    const [errorSubmit, setErrorSubmit] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleOnChange = (key, value) => {
        setForm({
            ...form,
            [key]: value
        })
    }

    const handleOnSubmit = () => {
        if (form.email !== '' && form.name !== '') {
            sendContactInfo(form).then(res => {
                if (res.status !== 200) {
                    setErrorSubmit(true);
                } else {
                    setIsSubmitted(true);
                    setErrorSubmit(false);
                    setForm({
                        name: '',
                        email: '',
                        message: '',
                        tag
                    })
                }
            });
        } else {
            setError(true)
        }
    }

    return (
        <div className="ContactBlock">
            <h1 className={`ContactBlock__title--${textColor ? textColor : 'white'}`}>{title}</h1>
            <TextBlock text={text} noPadding={true} alignText="center" textColor={textColor} />
            <div className="ContactBlock__form">
                <div className="ContactBlock__inputs">
                    <InputText name="name" label="Name" value={form.name} handleOnChange={e => handleOnChange('name', e.target.value)} />
                    <InputText name="email" label="Email" value={form.email} handleOnChange={e => handleOnChange('email', e.target.value)} />
                </div>
                <div className="ContactBlock__inputs">
                    <TextArea label="Message" value={form.message} handleOnChange={e => handleOnChange('message', e.target.value)} />
                </div>
            </div>
            <div className="ContactBlock__actions">
                <div className="ContactBlock__action">
                    {!isSubmitted ? (
                        <Button onClick={handleOnSubmit}>
                            <div className="ContactBlock__button">
                                Send
                            </div>
                        </Button>
                    ) :
                        <p className={`ContactBlock__thankyou--${textColor ? textColor : 'white'}`}>{thankYouMessage || "Thank you, we will contact you shortly!"}</p>}
                        {!!(errorSubmit || error) && <p className={`ContactBlock__error--${textColor ? textColor : 'white'}`}>We are sorry! Something went wrong</p>}
                </div>
            </div>
        </div>
    )
}

export default ContactBlock;