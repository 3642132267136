import React from "react";
import Spline from "@splinetool/react-spline";
import './SplineBlock.scss';

const SplineBlock = ({ splineUrl, type }) => (
    <div className={`SplineBlock ${type === "background" ? "SplineBlock--background" : ""}`}>
        <Spline scene={splineUrl} />
    </div>
)

export default SplineBlock;