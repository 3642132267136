import React from "react";
import './ImageTitleBlock.scss';

const ImageTitleBlock = ({ title, image, textColor, height }) => (
    <div className="ImageTitleBlock">
        <div className="ImageTitleBlock__image" style={{ height: `${height ? height : 250}px` }}>
            <img alt={image.fields.title} src={image.fields.file.url} />
        </div>
        <h2 className={`ImageTitleBlock__title--${textColor ? textColor : 'white'}`}>{title}</h2>
    </div>
)

export default ImageTitleBlock;