import React from "react";
import BarChart from "../BarChart/BarChart";
import constants from "../../constants";
import GridColumn from "../gridSystem/GridColumn/GridColumn";
import Grid from "../gridSystem/Grid/Grid";
import Block from "../Block/Block";
import Speedometer from "../Speedometer/Speedometer";
import './Overview.scss';

const OverviewL0 = ({ score, data, loading }) => (
    <div className='Overview__barchart'>
        <Grid>
            {Object.values(constants.l0).map(level => (
                <GridColumn key={level.id} size={4} noPadding={true} height="100%">
                    <Block color="white" fill={true} gridStyle={{ gridTemplateRows: '160px' }}>
                        <>
                            <div className='Overview__center'>
                                <Speedometer
                                    score={Math.round(!loading && data ? data[level.id].score : score)}
                                    color={constants.l0[level.id].colorCode}
                                    size={10}
                                    pinBorder={false}
                                    fontSize="fs-xxxl"
                                    arcWidth={0.2}
                                />
                            </div>
                            <div>
                                <h2 className='Overview__title Overview--desktop'>{constants.l0[level.id].name}</h2>
                                <h2 className={`Overview__title Overview__title--${constants.l0[level.id].color} Overview__title--mobile`}>{constants.l0[level.id].name}</h2>
                                <p>{data ? data[level.id].description : ''}</p>
                            </div>
                            <BarChart
                                data={[{
                                    id: level.id,
                                    color: constants.l0[level.id].color,
                                    colorCode: constants.l0[level.id].colorCode,
                                    data: data ? Object.values(data[level.id].children).map(child => ({ id: level.id, label: child.description, score: child.score })) : []
                                }]}
                            />
                        </>
                    </Block>
                </GridColumn>
            ))}
        </Grid>
    </div>
);

export default OverviewL0;