import React from "react";
import Alert from "../Alert/Alert";
import Hero from "../Hero/Hero";
import Page from "../Page/Page";

const Layout = ({ score, data, onClick, error, loading, name, tag }) => (
    <>
        {error && <Alert />}
        <Hero score={score} onClick={onClick} error={error} name={name} tag={tag} />
        <Page score={score} data={data} loading={loading} error={error} />
    </>
)

export default Layout;