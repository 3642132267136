import React from "react";
import BlocksContainer from "../../container/BlocksContainer";
import LayoutSection from "../LayoutSection/LayoutSection";
import './ContentfulPage.scss';

const ContentfulPage = ({ content }) => !!content && (
    <div className="ContentfulPage">
        {content.sections.map((section) => (
            <LayoutSection key={section.sys.id} backgroundColor={section.fields.backgroundColor} backgroundImage={section.fields.backgroundImage}>
                <BlocksContainer key={section.sys.id} type={section.sys.contentType.sys.id} content={section} />
            </LayoutSection>
        ))}
    </div>
)

export default ContentfulPage;