import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { HiMenu } from "react-icons/hi";
import Button from "../Button/Button";
import LoginButton from "../LoginButton/LoginButton";

const Header = ({ user }) => {
    const { logout } = useAuth0();
    const [openMenu, setOpenMenu] = useState(false);

    return (
        <div className="App__header">
            <div className="App__logo">
                <img alt="TransitionView logo" src="https://lh3.googleusercontent.com/j8HZN0_FPqvP12UFN3Y5V8B7FGLX5WfRMi71tXEq8hIUZ99OmILfHreKTxIJ2XXJl0uQraFvGCXANJU6ttAVODJtkxhTzpOqvsrTex8dNL3LJH1H-wvYhqfGZhgccY4e7PSO8lthcg=w2400" />
            </div>
            <div className="App__headerRight--mobile">
                <div className="App__menuButton"><Button color='transparent' onClick={() => setOpenMenu(!openMenu)}><HiMenu /></Button></div>
                {openMenu && (
                    <div className="App__menu">
                        <p className="App__menuItem">{user.nickname} - {user.company}</p>
                        <p className="App__menuItem" onClick={() => logout({ returnTo: window.location.origin })}>Log out</p>
                    </div>
                )}
            </div>
            <div className="App__headerRight">
                <p className="App__headerName">{user.nickname} - {user.company}</p>
                <LoginButton />
            </div>
        </div>
    )
}

export default Header;