import axios from "axios"
import { generateToken } from "../helpers";
const url = process.env.REACT_APP_TCF_API;

export const getTypeformByToken = async (typeformToken, token) => axios.get(url + '/typeform/specific/' + typeformToken, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => ({ ...res.data, status: 200 }))
    .catch(error => error.response);

export const updateUserData = async (user, company) => axios({
    method: 'patch',
    url: process.env.REACT_APP_AUTH0_API + 'users/' + user.sub,
    headers: {
        'authorization': `Bearer ${process.env.REACT_APP_AUTH0_TOKEN}`,
        'content-type': 'application/json'
    },
    data: { user_metadata: { company, typeformToken: user.typeformToken || generateToken() } }
})
    .then(res => ({ ...res.data.user_metadata, status: 200 }))
    .catch(error => error.response);

export const sendContactInfo = async (form, token) => axios({
    method: 'post',
    url: url + '/messages',
    data: form
})
    .then(res => res)
    .catch(e => e);

export const getTypeformByIdAndToken = async (typeformId, typeformToken, token) => axios.get(`${url}/typeform/${typeformId}/id/${typeformToken}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => ({ ...res.data, status: 200 }))
    .catch(error => error.response);

export const submitAnswers = async (token, typeformId, typeformToken, companyId, companyName) => axios({
    method: 'post',
    url: `${url}/surveys/${typeformId}/answers`,
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: {
        typeformToken,
        companyId,
        companyName
    }
}).catch(error => error.response);