import React from "react";
import './Tooltip.scss';

const Tooltip = ({ text, hidden }) => {
    return (
        <div className={`Tooltip${hidden ? '--hidden' : ''}`}>
            {text}
        </div>
    )
}

export default Tooltip;