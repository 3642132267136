import React from "react";
import colors from "../../styles/colors";

const Curve = ({ score }) => {
    // Define points for the Bezier curve
    const radius = 32;
    const width = 783;
    const height = 150;
    const yOffset = 3;

    const p0 = { x: radius, y: height };
    const p1 = { x: 300, y: height };
    const p2 = { x: 522, y: radius };
    const p3 = { x: width, y: radius };

    const bezierPoint = t => {
        const x = Math.pow(1 - t, 3) * p0.x + 3 * Math.pow(1 - t, 2) * t * p1.x + 3 * (1 - t) * t ** 2 * p2.x + t ** 3 * p3.x;
        const y = Math.pow(1 - t, 3) * p0.y + 3 * Math.pow(1 - t, 2) * t * p1.y + 3 * (1 - t) * t ** 2 * p2.y + t ** 3 * p3.y;
        return { x, y };
    };

    const scorePosition = bezierPoint(score / 100);

    const path = `M ${p0.x},${p0.y} C ${p1.x},${p1.y} ${p2.x},${p2.y} ${p3.x},${p3.y} L ${p3.x},${p3.y} ${p3.x},${p0.y + yOffset} L ${p3.x},${p0.y + yOffset} ${p0.x},${p0.y + yOffset}`;

    const colorZone = {
        0: "rgba(247, 85, 85, 1)",
        10: "rgba(255, 139, 102, 1)",
        66: "rgba(255, 209, 102, 1)",
        100: "rgba(45, 176, 100, 1)"
    }

    return (
        <svg height="100%" width="100%" viewBox={`0 0 ${width + radius} ${height + radius}`} style={{ marginBottom: `-${radius/2}px` }}>
            <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{ stopColor: colorZone[0], stopOpacity: 1 }} />
                    <stop offset="10%" style={{ stopColor: colorZone[10], stopOpacity: 1 }} />
                    <stop offset="66%" style={{ stopColor: colorZone[66], stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: colorZone[100], stopOpacity: 0.804 }} />
                </linearGradient>
            </defs>
            <path d={path} fill="url(#gradient)" />
            <rect x={scorePosition.x} y="0" width={width - scorePosition.x} height={height + yOffset} fill={colors["color__gray-5"]} opacity={0.4} />
            <line x1={scorePosition.x} y1={scorePosition.y} y2={height + yOffset} x2={scorePosition.x} stroke={colors["color__gray-5"]} strokeWidth={4} />
            <circle
                cx={scorePosition.x}
                cy={scorePosition.y}
                r={radius}
                fill={
                    (score < 10 && colorZone[0]) ||
                    (score < 50 && colorZone[10]) ||
                    (score < 80 && colorZone[66]) ||
                    colorZone[100]
                }
                stroke="white"
                strokeWidth={4}
            />
            <text
                x={scorePosition.x}
                y={scorePosition.y + 3}
                fill="white"
                fontSize="28"
                fontWeight="bold"
                textAnchor="middle"
                alignmentBaseline="middle"
                dominantBaseline="middle"
            >
                {score}
            </text>
        </svg>
    );
};

export default Curve;