import React, { useEffect, useState } from "react";
import ProgressBarDot from "./ProgressBarDot";
import "./ProgressBar.scss";

const ProgressBar = ({ prefix, progress, color, gradient, withNumber, height, hasBackground, backgroundColor, hasDot, dotColor, fadeTo, tooltip, secondary, fontWeight, fontSize, fontColor, dotIcon, tail, isHidden }) => {
    const [isInterval, setIsInterval] = useState(null);
    const [progressWidth, setProgressWidth] = useState(0);

    useEffect(() => {
        setIsInterval(typeof progress === 'object')
        if (typeof progress === 'object') {
            setProgressWidth(Math.max(...progress) - Math.min(...progress));
        } else {
            setProgressWidth(progress);
        }
    }, [progress]);

    return (
        <div
            className={`ProgressBar ProgressBar${isHidden ? '--hidden' : '--visible'}`}
            style={{ ...(height ? { height } : {}) }}
        >
            {hasBackground && <span className={`ProgressBar__background${backgroundColor ? `--${backgroundColor}` : ''}`}></span>}
            {secondary && <div className={`ProgressBar__background`} style={{ width: `${progress !== 0 ? secondary : 1}%` }}></div>}
            <div className={`ProgressBar__progress ProgressBar__progress--${isInterval ? 'transparent' : color}${fadeTo ? `--${fadeTo}` : ''} ${gradient ? `ProgressBar__progress--gradient` : ''}`} style={{ width: `${progressWidth !== 0 ? (isInterval ? 100 : progressWidth) : 1}%` }}>
                {isInterval && <div className="ProgressBar__progress--interval" style={{ width: `${progressWidth !== 0 ? progressWidth : 1}%`, marginLeft: `${Math.min(...progress)}%` }}></div>}
                {!!(withNumber && !hasDot) && <span className={`ProgressBar__number${progress > 90 ? '--left' : ''}`} style={{ ...(fontWeight ? { fontWeight } : {}), ...(fontColor ? { color: fontColor } : {}) }}>{progress}</span>}
            </div>
            {(hasDot && !isInterval) && (
                <ProgressBarDot
                    left={`${progress}%`}
                    color={dotColor || color}
                    prefix={prefix}
                    value={progress}
                    fontSize={fontSize}
                    tail={tail}
                    tooltip={tooltip}
                    dotIcon={dotIcon}
                    withNumber={withNumber}
                />
            )}
            {isInterval && progress.sort((a, b) => a - b).map((point, index) => {
                let offset = 0;
                if (progress.filter(p => p === point).length > 1) {
                    const leftLength = progress.slice(0, index).filter(p => p === point).length;
                    const rightLength = progress.slice(index + 1, progress.length).filter(p => p === point).length;
                    const side = leftLength < rightLength ? -1 : 1;
                    offset = leftLength < rightLength ? leftLength - progress.filter(p => p === point).length / 2 + 1 : rightLength;
                    offset *= 3;
                    offset += 2.5 * side;
                }

                return (
                    <ProgressBarDot
                        left={`${point}% + ${offset}px`}
                        color={dotColor || color[index]}
                        prefix={prefix[index]}
                        value={point}
                        borderColor='transparent'
                        fontSize={fontSize}
                        tail={tail}
                        tooltip={tooltip}
                        dotIcon={dotIcon}
                    />
                );
            })}
        </div>
    )
}

export default ProgressBar;