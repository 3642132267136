import React from "react";
import TextBlock from "../TextBlock/TextBlock";

const VideoBlock = ({ textOnVideo, video }) => (
    <div className="VideoBlock">
        <video src={video.fields.file.url} muted loop autoPlay />
        <TextBlock text={textOnVideo} />
    </div>
)

export default VideoBlock;