import React, { useEffect, useState } from "react";
import { getContentById } from "../../api/contentful";
import BlocksContainer from "../../container/BlocksContainer";
import './LayoutBlock.scss';

const LayoutBlock = ({ blocks, backgroundImage, alignEntries }) => {
    const [entries, setEntries] = useState([]);
    useEffect(() => {
        const getEntries = async blocks => {
            for (let i = 0; i < blocks.length; i++) {
                const section = blocks[i];
                let entry = await getContentById(section.sys.id);
                setEntries(entries => [...entries, entry]);
            }
        }
        getEntries(blocks);
    }, [blocks]);

    return (
        <div className={`LayoutBlock--${alignEntries ? alignEntries : 'left'} ${!!backgroundImage ? 'LayoutBlock--backgroundImage' : ''}`} style={{ ...(backgroundImage ? { backgroundImage: `url(${backgroundImage.fields.file.url})` } : {}) }}>
            {entries.map(entry => (
                <BlocksContainer key={entry.sys.id} type={entry.sys.contentType.sys.id} content={entry} noPadding={true} />
            ))}
        </div>
    )
}

export default LayoutBlock;