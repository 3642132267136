import React from "react";
import './TextArea.scss';

const TextArea = ({ label, value, handleOnChange }) => {
    return (
        <div className="TextArea">
            <textarea className="TextArea__area" value={value} onChange={handleOnChange} />
            <label className="TextArea__label">{label}</label>
        </div>
    )
}

export default TextArea;