import React from 'react';
import './Block.scss';

const Block = ({ title, children, onClick, color, fill, gridStyle }) => (
    <div className={`Block${color ? `--${color}` : ''} ${fill ? 'Block--fill' : ''}`}>
        <div className="Block__wrapper" style={gridStyle}>
            {!!title && (
                <div className="Block__header">
                    <div className='Block__title'>{title}</div>
                    <div className="Block__action">
                        {onClick && <div className='Block__menu' onClick={onClick}></div>}
                    </div>
                </div>
            )}
            {children}
        </div>
    </div>
)

export default Block;