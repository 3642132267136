import React from "react";
import logo from '../../assets/img/anothertomorrow_logo.png';
import './Footer.scss';

const Footer = () => (
    <div className="Footer">
        <div className="Footer__img">
            <a href="https://www.anothertomorrow.com/"><img alt="Another Tomorrow Logo" src={logo} /></a>
        </div>
    </div>
)

export default Footer;