import React from "react";
import './ContactForm.scss';

const ContactForm = ({ form, onChange, error }) => (
    <div className="ContactForm">
        <div className="ContactForm__item">
            <label className={`${error && form.name === '' ? 'ContactForm__required' : ''}`}>Name*:</label>
            <input type="text" value={form.name} name="name" onChange={onChange} />
        </div>
        <div className="ContactForm__item">
            <label className={`${error && form.email === '' ? 'ContactForm__required' : ''}`}>Email*:</label>
            <input type="email" value={form.email} name="email" onChange={onChange} />
        </div>
        <div className="ContactForm__item">
            <label>Phone:</label>
            <input type="phone" value={form.phone} name="phone" onChange={onChange} />
        </div>
        <div className="ContactForm__item">
            <label>Message:</label>
            <textarea type="text" value={form.message} name="message" onChange={onChange} />
        </div>
        <p className="ContactForm__small">(*) Required</p>
    </div>
)

export default ContactForm;