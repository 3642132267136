import React from "react";
import './Alert.scss';

const Alert = ({ text }) => {
    return (
        <div className="Alert">
            <div className="Alert__box">
                <p>{!!text ? text : "We're sorry, we are still working on getting your result in!"}</p>
            </div>
        </div>
    )
}

export default Alert;