import React, { cloneElement, useLayoutEffect, useRef, useState } from "react";
import Tooltip from "../Tooltip/Tooltip";

const ProgressBarDot = ({ left, color, prefix, value, borderColor, withNumber, fontSize, tail, tooltip, dotIcon }) => {
    const ref = useRef();
    const [pos, setPos] = useState(null);
    useLayoutEffect(() => {
        setPos(ref.current.getBoundingClientRect().height / 2);
    }, []);
    return (
        <div ref={ref} className={`ProgressBar__dot ProgressBar__dot--${color} ProgressBar__dot--interval ${tail ? 'ProgressBar__dot--tail' : ''}`} style={{ left: `calc(${left} - ${pos}px)`, borderColor }}>
            {withNumber && <span className={`ProgressBar__font--${fontSize}`}>{value}</span>}
            <div className="ProgressBar__tooltip">
                {tooltip && <Tooltip text={<p className={`ProgressBar__tooltip--text${color}`}><span className="ProgressBar__tooltip--title">{prefix}</span><b>{value}</b></p>} />}
            </div>
            {dotIcon && cloneElement(dotIcon, { height: 11, width: 11 })}
        </div>
    )
};

export default ProgressBarDot;