import React from "react";
import MediaBlock from "../MediaBlock/MediaBlock";
import TextBlock from "../TextBlock/TextBlock";
import './TextAndImageBlock.scss';

const TextAndImageBlock = ({ title, text, textColor, image, headerOnTop, imageToTheRight }) => {
    const getText = <TextBlock text={text} textColor={textColor} noPadding={true} />;
    const getTitle = <h2 className={`TextAndImageBlock__title${textColor ? `--${textColor}` : ''}`}>{title}</h2>
    const getImage = (!!image && !!image.fields.file) && <div className="TextAndImageBlock__image"><MediaBlock {...image} /></div>;

    if (headerOnTop) {
        return (
            <div className="TextAndImageBlock">
                <div className="TextAndImageBlock__title--center">
                    {getTitle}
                </div>
                <div className="TextAndImageBlock__wrapper">
                    <div className={`TextAndImageBlock__block${imageToTheRight ? '--right' : '--left'}`}>
                        <div className="TextAndImageBlock__text">{getText}</div>
                        {getImage}
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div className="TextAndImageBlock">
            <div className="TextAndImageBlock__wrapper">
                <div className={`TextAndImageBlock__block${imageToTheRight ? '--right' : '--left'}`}>
                    <div className="TextAndImageBlock__text">
                        <div className="TextAndImageBlock__title">
                            {getTitle}
                        </div>
                        {getText}
                    </div>
                    {getImage}
                </div>
            </div>
        </div>
    )
};

export default TextAndImageBlock;